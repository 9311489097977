'use strict';

var globalErrorMsg = {
  globalMsg: "Internal server Error. ",
  globalJavaScriptMsg: "Internal Error. "
};
var globalSuccMsg = {
  OtpSendSuc: "Otp Send Successfully"
};

var messageConfig = function () {
  var messages = {
    validationMsg: {
      required: "Please enter value for {0} ",
      matches: 'The {0} field does not match the {field} field.',
      default: 'The {0} field is still set to default, please change.',
      email: 'The {0} field must contain a valid email address.',
      emails: 'The {0} field must contain all valid email addresses.',
      exact_length: 'The {0} field must be exactly {} characters in length.',
      minlength: "{0} must be at least {minlength} character(s).",
      maxlen: "{0} must be less than {maxlen} characters.",
      min: "{0} must be at least {1}.",
      max: "{0} must be at most {1}.",
      greater_than: 'The {0} field must contain a number greater than %s.',
      less_than: 'The {0} field must contain a number less than %s.',
      pattern: "{0} is invalid.",
      url: "{0} must be a valid URL.",
      number: "{0} must be a number.",
      unknown: "{0} is invalid.",
      validationTitle: 'There are some validation errors',
      passwordMatch: "These passwords don't match.",
      tel: "Please enter a valid Mobile Number",
      alpha: 'The {0} field must only contain alphabetical characters.',
      alpha_numeric: 'The {0} field must only contain alpha-numeric characters.',
      alpha_dash: 'The {0} field must only contain alpha-numeric characters, underscores, and dashes.',
      numeric: 'The {0} field must contain only numbers.',
      integer: 'The {0} field must contain an integer.',
      decimal: 'The {0} field must contain a decimal number.',
      is_natural: 'The {0} field must contain only positive numbers.',
      is_natural_no_zero: 'The {0} field must contain a number greater than zero.',
      valid_ip: 'The {0} field must contain a valid IP.',
      valid_base64: 'The {0} field must contain a base64 string.',
      valid_credit_card: 'The {0} field must contain a valid credit card number.',
      is_file_type: 'The {0} field must contain only {1} files.',
      valid_url: 'The {0} field must contain a valid URL.',
      greater_than_date: 'The {0} field must contain a more recent date than {1}.',
      less_than_date: 'The {0} field must contain an older date than {1}.',
      greater_than_or_equal_date: 'The {0} field must contain a date that\'s at least as recent as {1}.',
      less_than_or_equal_date: 'The {0} field must contain a date that\'s {1} or older.',
      numberonly: "{0} must be a number.",
      confirmation: "{0} is not equal to {originalLabel}"
    },
    homeMsg: {
      1: "Locate Me",
      2: "Download The App"
    },
    serverError: {
      error: "404 not found"
    },
    errorMsg: {
      common: "Error in javascript"
    }
  };
  return {
    //main function to initiate the module
    init: function init() {
      return messages;
    }
  };
}();

var regexConfig = function () {
  var regex = {
    ruleRegex: /^(.+?)\[(.+)\]$/,
    numericRegex: /^[0-9]+$/,
    integerRegex: /^\-?[0-9]+$/,
    decimalRegex: /^\-?[0-9]*\.?[0-9]+$/,
    emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    alphaRegex: /^[a-z]+$/i,
    alphaNumericRegex: /^[a-z0-9]+$/i,
    alphaDashRegex: /^[a-z0-9_\-]+$/i,
    naturalRegex: /^[0-9]+$/i,
    naturalNoZeroRegex: /^[1-9][0-9]*$/i,
    ipRegex: /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i,
    base64Regex: /[^a-zA-Z0-9\/\+=]/i,
    numericDashRegex: /^[\d\-\s]+$/,
    urlRegex: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
    dateRegex: /\d{4}-\d{1,2}-\d{1,2}/,
    telRegex: /^\d+$/
  };
  return {
    //main function to initiate the module
    init: function init() {
      return regex;
    }
  };
}();

var formInputClassConfig = function () {
  var formInputClass = {
    // div/node class name selector which contains one label, one input, one help text etc.
    classInputGroup: 'ico-form-group',
    // class to be applied on input group node if it has invalid input
    classInputGroupError: 'has-error',
    // class to be applied on input group node if it input passed validation (is valid)
    classInputGroupSuccess: 'has-info',
    // label to pick textContent from to insert field name into error message
    classLabel: 'form-control-label',
    // error message tag name
    tagNameError: 'span',
    // error message class
    classError: 'help-block-error-custom',
    // query selector to search inputs within input groups to validate
    selectorInput: '[name]',
    classServerError: 'help-error-server'
  };
  return {
    //main function to initiate the module
    init: function init() {
      return formInputClass;
    }
  };
}();